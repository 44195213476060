<template>
  <div style="height: 100%">
    <el-container style="height: 100%">
      <!-- / 左侧菜单 -->
      <el-aside style="width: auto !important">
        <frAside></frAside>
      </el-aside>
      <el-container>
        <!-- / 头部标题 -->
        <el-header></el-header>

        <el-main class="fr_main_div">
          <el-row :gutter="35">
            <el-col :lg="15" :sm="24">
              <div class="block">
                <el-timeline v-for="(item, index) in updta" :key="index">
                  <el-timeline-item :timestamp="item.up_time" placement="top">
                    <el-card>
                      <h4 v-html="item.up_center"></h4>
                      <p>{{ item.up_user }} 提交于 {{ item.up_bv }}</p>
                    </el-card>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-col>
            <el-col :lg="9"> </el-col>
          </el-row>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { GetfrDoc } from "../../components/api";
import axios from "axios";
import aside from "../../components/aside.vue";
export default {
  components: {
    frAside: aside,
  },
  data() {
    return {
      updta: {},
    };
  },
  mounted() {
    this.frdoc();
  },

  methods: {
    frdoc() {
      axios
        .get(GetfrDoc)
        .then((res) => {
          this.updta = res.data.updta;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped>
/* car样式定义 */
.el-card {
  border: unset;
}
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: unset;
}
/* macin主体内容 */
.fr_main_div .el-header {
  background: rgb(238, 240, 227);
}

.el-aside {
  background-color: #ffffff;
}
/* 深色模式 */
@media (prefers-color-scheme: dark) { 
  .el-main,.el-header { 
    background-color: #1f2a38 !important;
  }
  .el-card{
    background-color: #101927 !important;
    color: #ffffff !important;
  }
}
</style>